import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="What is Melomania?" text="A decentralized music platform aiming to help support and fund artists while utilizing modern Web3 technologies. Want to share your music? Head over to the market? Want to chat with other producers and create a tight knit community? Head to the chatrooms! Have any questions? Check the FAQ!" />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">we aim to tighten the music community. <br /><br /> create. share. inspire.</h1>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Marketplace / Mint NFT!" text="Share your music to the marketplace! Receive tips from the people who love your work!" />
      <Feature title="Chatrooms" text="Chat with other producers. With the option to create chat forums, the conversations are endless." />
      <Feature title="Community Crowdsourcing" text="Everyone should have the opportunity to produce music. Help gather funds to push out more great albums!" />
    </div>
  </div>
);

export default WhatGPT3;
