import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Tip your favorite producers',
    text: 'We’ll help you tip your favorite producers to get them started on becoming a bigger producer.',
  },
  {
    title: 'Build a tight community',
    text: 'With the ability to create custom chat rooms, users are able to freely chat with other producers utilzing GUN technologies to remain decentralized.',
  },
  {
    title: 'Gasless Transactions',
    text: 'Coming soon!',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Step into the music metaverse. Get your foot into Web3 while doing what you love, producing!</h1>
      <p>Request Early Access to Get Started</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
