import React from 'react';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">Join Melomania today. Follow our twitter to learn more! <a href="https://www.twitter.com/melomania_eth" target="_blank" rel="noopener noreferrer">@melomania_eth</a></h1>
    </div>
  </div>
);

export default Blog;
