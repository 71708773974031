import React from 'react';
import synth from '../../assets/ai.png';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Melomania</h1>
      <p>a decentralized music marketplace and crowdsourcing platform</p>
    </div>

    <div className="gpt3__header-image">
      <img src={synth} />
    </div>
  </div>
);

export default Header;
