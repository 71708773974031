import React from 'react';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-content">
      <h1 className="gradient__text">How the marketplace works</h1>
      <p>After connecting your Metamask wallet, you will be granted access to the marketplace. Here, you are able to upload .wav, .mp3, and .mp4 files. After entering a fitting description for your track, submit and confirm the Metamask transaction. Upon completion, your page will automatically be reloaded with your post available! Clicking on the more button shows expanded details about the post such as the IPFS link, and more information about the creator.</p>
      <h4>Request Early Access to Get Started</h4>
    </div>
  </div>
);

export default Possibility;
